import { BIRDI_PLANS } from 'enums/plans';
import { sortBy } from 'lodash';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { cartItemsSelector } from 'state/cart/cart.selectors';
import { setMedicineCabinetShouldRefresh } from 'state/medicine-cabinet/medicine-cabinet.reducers';
import { medicineCabinetLoadRoutine } from 'state/medicine-cabinet/medicine-cabinet.routines';
import {
    medicineCabinetActiveTabSelector,
    medicineCabinetPrescriptionsSelector,
    medicineCabinetShouldRefreshSelector
} from 'state/medicine-cabinet/medicine-cabinet.selectors';
import { membershipIsOnDemandSelector } from 'state/membership/membership.selector';

import { PrescriptionCardProps, RxDetails } from 'types/prescription';

import { searchRxNumberInCart } from 'util/cart';
import { prescriptionPayloadToProps } from 'util/payload-to-props';
import { doesPlanAllowsPricing } from 'util/pricing';

import usePrescriptionPrice from 'hooks/usePrescriptionPrice';

import usePrescriptionFlow from './usePrescriptionFlow';

export interface PriceHistory {
    planAlias: string;
    zipCode: string;
    drugCode: string;
}

const useMedicineCabinetPrescriptions = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const cartItemsObject = useSelector(cartItemsSelector);
    const prescriptionsSelector = useSelector(medicineCabinetPrescriptionsSelector);
    const isOnDemandPlan = useSelector(membershipIsOnDemandSelector);
    const activeTab = useSelector(medicineCabinetActiveTabSelector);
    const { showInsurancePrice, pricingZipCode, getPrescriptionPrices, mapPrescriptionsWithPrice } =
        usePrescriptionPrice();
    const { familyPlanList, ownerPlan } = usePrescriptionFlow();
    const shouldRefreshMedCabinet = useSelector(medicineCabinetShouldRefreshSelector);

    useEffect(() => {
        if (shouldRefreshMedCabinet) {
            dispatch(medicineCabinetLoadRoutine.trigger({ fetchRxSubStatus: true, selectedDependent: activeTab }));
            dispatch(setMedicineCabinetShouldRefresh(false));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [shouldRefreshMedCabinet]);

    // Get the planAlias of the selected user in the medicine cabinet
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const selectedPlan = useMemo(() => (familyPlanList[activeTab] || ownerPlan) as BIRDI_PLANS, [ownerPlan, activeTab]);

    // Validate if the user's plan should display prices
    const shouldPlanShowPrice: boolean = useMemo(
        () => showInsurancePrice || doesPlanAllowsPricing(selectedPlan),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [showInsurancePrice, selectedPlan]
    );

    // Instead using Prescription Selector data to list all prescriptions,
    // we store its data to manipulate some parameters without the need of sending
    // a new dispatch do collect new data to know what prescriptions were added to the cart.
    const rawPrescriptions: RxDetails[] = useMemo(
        () =>
            prescriptionsSelector.map((prescription) => {
                const isInCart = searchRxNumberInCart(prescription.rxNumber, cartItemsObject);
                return { ...prescription, ...{ inOrderCart: isInCart } };
            }),
        [prescriptionsSelector, cartItemsObject]
    );

    // We process once the prescription card data transforming
    // it to the correspondent object to render prescription cards
    const prescriptionCards: PrescriptionCardProps[] = useMemo(
        () =>
            rawPrescriptions.map((prescription) =>
                prescriptionPayloadToProps(
                    prescription,
                    t,
                    selectedPlan,
                    // TODO: For now we are using on demand validation of the
                    // parent's user, to check if we need to do it at the dependent level
                    isOnDemandPlan
                )
            ),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [rawPrescriptions]
    );

    // When all the information has been loaded, validate which Rxs are eligible to
    // get pricing from api
    const priceEligiblePrescriptions: RxDetails[] = useMemo(() => {
        if (!!pricingZipCode && prescriptionCards.length > 0 && shouldPlanShowPrice) {
            return prescriptionCards.filter((rx) => rx.showPrice).map((rx) => rx.fullRxItem);
        } else {
            return [];
        }
    }, [pricingZipCode, prescriptionCards, shouldPlanShowPrice]);

    // Adding the price to the prescriptions
    const prescriptionCardsWithprice: PrescriptionCardProps[] = useMemo(
        () => mapPrescriptionsWithPrice(prescriptionCards, shouldPlanShowPrice, selectedPlan),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [prescriptionCards, mapPrescriptionsWithPrice]
    );

    // We process once the prescription card data transforming
    // it to the correspondent object to render prescription cards
    const sortedPrescriptions: PrescriptionCardProps[] = useMemo(
        () => sortBy(prescriptionCardsWithprice, ['sortOrder', 'prescriptionName']),
        [prescriptionCardsWithprice]
    );

    // Trigger event to get prices from rx once information has been loaded
    useEffect(() => {
        if (priceEligiblePrescriptions.length > 0) {
            getPrescriptionPrices(priceEligiblePrescriptions);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [priceEligiblePrescriptions]);

    return { prescriptions: rawPrescriptions, sortedPrescriptions };
};

export default useMedicineCabinetPrescriptions;
